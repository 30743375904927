const baseUrl = "https://mojkesaudagar-backend.vercel.app";
// const baseUrl = "http://localhost:4000";
// const baseUrl = "https://mojkesaudagar-backend.vercel.app";
export const BannersAPI = baseUrl + "/banners";
export const ProductAPI = baseUrl + "/products";
export const TestimonialAPI = baseUrl + "/testimonial";
export const CertificatesAPI = baseUrl + "/certificates";
export const DestinationAPI = baseUrl + "/destination";
export const BlogAPI = baseUrl + "/blogs";
export const TourListAPI = baseUrl + "/tour-list";
export const LeadAPI = baseUrl + "/lead";
export const CategoryAPI = baseUrl + "/categories";
export const DreamPLaceAPI = baseUrl + "/dream-place";
export const HomePageAPI = baseUrl + "/home";
export const AboutPageAPI = baseUrl + "/about";
export const FavouriteDestinationAPI = baseUrl + "/favourite-destination";
export const ImageAPI = baseUrl + "/img-upload";
export const UnitAPI = baseUrl + "/units";
export const NotificationAPI = baseUrl + "/notification";
export const LoginAPI = baseUrl + "/auth/login";
export const TourListPageAPI = baseUrl + "/tour-list-page";
export const BlogPageAPI = baseUrl + "/blog-page";
export const ContactUsAPI = baseUrl + "/contact-us-page";
export const CommentAPI = baseUrl + "/blog-comment";
